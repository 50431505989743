import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ReactTimeout from 'react-timeout';
import $ from 'jquery';
import logo from './../assets/logo.png';

class LogIn extends React.Component {
    constructor() {
        super();

        this.state = {
            email: '',
            password: '',
            message: null,
            snackbarStyle: {backgroundColor: 'darkred'},
        };

        this.logIn = this.logIn.bind(this);

        $("html, body").scrollTop(0);
    }

    logIn() {
        let email = this.state.email.trim();
        let password = this.state.password.trim();
        let AUTH = this.props.AUTH;

        if(email === '' || password === '') {
            this.setState({
                message: 'All fields are required.',
                snackbarStyle: {backgroundColor: 'darkred'},
                loading: false
            });

            this.props.setTimeout(() => this.setState({
                message: null
            }), 2000);
        } else {
            AUTH.signInWithEmailAndPassword(email, password).then((current_user) => {
                let admin_id = current_user.user.uid;
                // PERMISSION CHECK
                this.props.DB.collection('admins').doc(admin_id).onSnapshot((adminDoc) => {
                    if (AUTH.currentUser && adminDoc.exists && adminDoc.data().active) {
                        this.props.authorize();
                    } else {
                        this.props.unauthorize();
                        this.setState({message: "Access Denied"});
                    }
                })
            }).catch((error) => {
                this.setState({
                    message: error.message,
                    snackbarStyle: {backgroundColor: 'darkred'},
                    loading: false
                });
    
                this.props.setTimeout(() => this.setState({
                    message: null,
                    loading: false
                }), 3500);
            })
        }
    }

    render() {
        return(
            <React.Fragment>
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={this.state.message !== null}
                    onClose={() => this.setState({message: null})}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                >
                    <SnackbarContent
                        style={this.state.snackbarStyle}
                        message={this.state.message}
                    />    
                </Snackbar>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    style={{textAlign: 'center', minHeight: '100vh'}}
                >
                    <Grid xs={1} md={4} item/>
                    <Grid xs={10} md={4} item>
                        <img style={{width: '10rem', marginBottom: '1rem'}} src={logo} alt="Logo"/>
                        <Paper elevation={3} style={{padding: 24, borderRadius: 7}}>
                            <Typography style={{display: 'block', fontSize: '1.5rem', fontWeight: '200', color: '#57c069', marginBottom: '2rem'}}>Log In</Typography>
                            <TextField
                                disabled={this.state.loading}
                                label="Email"
                                type="email"
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.email}
                                variant="outlined"
                                onChange={(event) => this.setState({email: event.target.value})}
                            />
                            <TextField
                                disabled={this.state.loading}
                                label="Password"
                                type="password"
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    textAlign: 'left',
                                    backgroundColor: '#fff'
                                }}
                                value={this.state.password}
                                variant="outlined"
                                onChange={(event) => this.setState({password: event.target.value})}
                            />
                            <Button variant="contained" onClick={() => this.logIn()} style={{backgroundColor: '#233b53', width: '100%', color: '#fff', marginTop: 10}}>
                                Log In
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid xs={1} md={4} item/>
                </Grid>
            </React.Fragment>
        )
    }
}

export default ReactTimeout(LogIn)
