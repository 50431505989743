import React from 'react';
import {
    AppBar,
    Toolbar,
    Container,
    Grid,
    Paper,
    Typography,
    IconButton,
    MenuItem,
    CircularProgress,
    LinearProgress,
    TextField,
    Button,
    Snackbar,
    SnackbarContent,
    FormControl,
    InputLabel,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Chip
} from '@material-ui/core';
import {
    BarChart, 
    Bar, 
    XAxis, 
    YAxis, 
    Tooltip, 
    Legend,
    ResponsiveContainer
} from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import ReactTimeout from 'react-timeout';
import Numeral from 'numeral';
import $ from 'jquery';
import logo from './../assets/logo_white.png';
import CSS from '../css/Style';
import moment from 'moment';
import _ from 'underscore';
import { CSVDownload } from "react-csv";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            donorSearchVisible: false,
            donorEditingVisible: false,
            schoolEditingVisible: false,
            addSchoolFormVisible: false,
            message: null,
            keyword: "",
            snackbarStyle: {backgroundColor: 'darkred'},
            target: "all",
            scene: "funds_raised",
            hero: [],
            logo: [],
            schools: [],
            all_schools: [],
            formattedSchools: [],
            formattedSchoolMenuItems: [<MenuItem key="all" dense value="all">All Schools</MenuItem>],
            donors: [],
            formattedDonors: [],
            csvData: [],
            csvLinkVisible: false,
            data: {
                funds_raised: 0,
                contributions: 0,
                donors: 0
            },
            current_school: {
                id: "",
                name: "",
                active: "",
                service_fee_percentage: ""
            },
            current_donor: {
                id: "",
                name: "",
                active: ""
            },
            chart_data: {
                funds_raised: [
                    {name: 'May 2019', "Funds Raised": 3000},
                    {name: 'Jun 2019', "Funds Raised": 2000},
                    {name: 'Jul 2019', "Funds Raised": 2780},
                    {name: 'Aug 2019', "Funds Raised": 1890},
                    {name: 'Sep 2019', "Funds Raised": 2390},
                    {name: 'Oct 2019', "Funds Raised": 3490},
                ],
                round_up_contributions: [
                    {name: 'May 2019', "Round Ups": 190},
                    {name: 'Jun 2019', "Round Ups": 312},
                    {name: 'Jul 2019', "Round Ups": 250},
                    {name: 'Aug 2019', "Round Ups": 200},
                    {name: 'Sep 2019', "Round Ups": 219},
                    {name: 'Oct 2019', "Round Ups": 349},
                ],
                one_time_contributions: [
                    {name: 'May 2019', "One-Time Donations": 25},
                    {name: 'Jun 2019', "One-Time Donations": 37},
                    {name: 'Jul 2019', "One-Time Donations": 44},
                    {name: 'Aug 2019', "One-Time Donations": 75},
                    {name: 'Sep 2019', "One-Time Donations": 11},
                    {name: 'Oct 2019', "One-Time Donations": 26},
                ]
            }
        };

        $("html, body").scrollTop(0);

        // GET TOTAL FUNDS RAISED
        // GET TOTAL CONTRIBUTIONS
        // GET TOTAL DONORS
        this.getSchools = () => {
            props.DB.collection('schools').get().then((schoolsSnapshot) => {
                let schools = [];
                let formattedSchools = [];
                let formattedSchoolMenuItems = [<MenuItem key="all" dense value="all">All Schools</MenuItem>];
                let funds_raised = 0;
                let contributions = 0;
                let donors = 0;

                schoolsSnapshot.forEach((schoolDoc) => {
                    let school = schoolDoc.data();
                    school.id = schoolDoc.id;

                    funds_raised += school.funds_raised;
                    contributions += school.contributions;
                    donors += school.donors;
                    schools.push(school);

                    formattedSchoolMenuItems.push(<MenuItem key={school.id} dense value={school.id}>{school.name}</MenuItem>);
                })

                schools = _.sortBy(schools, 'name');
                
                // FORMAT SCHOOLS
                schools.forEach((school, schoolIndex) => {
                    formattedSchools.push(
                        <TableRow key={school.id} style={schoolIndex % 2 === 0 ? {backgroundColor: '#ccc'} : {backgroundColor: '#fff'}}>
                            <TableCell style={{textAlign: 'left'}}>{school.name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(school.donors).format('0,0')}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(school.contributions).format('0,0')}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(school.funds_raised/100).format('$0,0.00')}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(school.service_fee_percentage/100).format('0.00%')}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{school.active ? <CheckIcon style={{color: 'green'}}/> : <CloseIcon style={{color: 'red'}}/>}</TableCell>
                            <TableCell style={{textAlign: 'left'}}><IconButton onClick={() => this.editSchool(school)}><EditIcon style={{color: '#000'}}/></IconButton></TableCell>
                        </TableRow>
                    )
                }) 

                this.setState({
                    loading: false,
                    schools: schools,
                    formattedSchools: formattedSchools,
                    formattedSchoolMenuItems: formattedSchoolMenuItems,
                    data: {
                        funds_raised: funds_raised, 
                        contributions: contributions,
                        donors: donors
                    }
                });
            })
        };

        this.getSchools();
        
        // currentMonth = {moment().month(), moment.year()};
        let current = {month: moment().month(), year:  moment().year()};
        let prev1 = {month: moment().subtract(1, "month").month(), year:  moment().subtract(1, "month").year()};
        let prev2 = {month: moment().subtract(2, "month").month(), year:  moment().subtract(2, "month").year()};
        let prev3 = {month: moment().subtract(3, "month").month(), year:  moment().subtract(3, "month").year()};
        let prev4 = {month: moment().subtract(4, "month").month(), year:  moment().subtract(4, "month").year()};
        let prev5 = {month: moment().subtract(5, "month").month(), year:  moment().subtract(5, "month").year()};
        console.log(current, prev1, prev2, prev3, prev4, prev5);

        let chartDataQueryTargets = [current, prev1, prev2, prev3, prev4, prev5]; // {month, year}

        // GET CHART DATA
        chartDataQueryTargets.forEach((target) => {
            props.DB.collection('transactions').where("month", "==", target.month).where("year", "==", target.year).get().then((transactionsSnapshot) => {
                let funds_raised = 0;

                transactionsSnapshot.forEach((transactionDoc) => {
                    let transaction = transactionDoc.data();

                    funds_raised += transaction.spare_change;
                })
            })
        })


        this.updateTarget = this.updateTarget.bind(this);
        this.getDonors = this.getDonors.bind(this);
        this.toggleDonorSearchVisibility = this.toggleDonorSearchVisibility.bind(this);
        this.toggleAddSchoolFormVisibility = this.toggleAddSchoolFormVisibility.bind(this);
        this.addImage = this.addImage.bind(this);
        // this.uploadImage = this.uploadImage.bind(this);
    }

    addImage(event) {
        this.setState({loading: true});
        let FILE = event.target.files[0];
        this.setState({image: FILE, loading: false});
    }

    // uploadImage(type) {
    //     this.setState({loading: true});
    //     let hero_image = this.state.hero_image;
    //     let logo_image = this.state.logo_image;
    //     let STORAGE = this.props.STORAGE;
    //     let hero_image_ref = STORAGE.child(`schools/images/${school_id}/hero`);
    //     let logo_image_ref = STORAGE.child(`school/images/${school_id}/logo`)

    //     hero_image_ref.put(FILE).then((FILE_SNAPSHOT) => {
    //         FILE_SNAPSHOT.ref.getDownloadURL().then((URL) => {
    //             let school_ref = this.props.DB.collection("schools").doc(school_id);
    //             school_ref.update({
    //                 logo: URL
    //             });
    //         });
    //         this.setState({message: 'created successfully!', showNotification: true, loading: false});
    //         this.props.setTimeout(() => {this.props.changeScene('all_events')}, 2500);
    //     }).catch((error) => {
    //         this.setState({
    //             showNotification: true,
    //             message: error.message,
    //             error: {
    //                 show: true,
    //                 message: error.message
    //             }
    //         });

    //         this.props.setTimeout(() => this.setState({
    //             error: {
    //                 message: '',
    //                 show: false
    //             }
    //         }), 3500);
    //     });
    // }

    updateTarget(school_id) {
        this.setState({loading: true});
        if (school_id === "all") {
            this.props.DB.collection('schools').get().then((schoolsSnapshot) => {
                // let schools = [];
                let funds_raised = 0;
                let contributions = 0;
                let donors = 0;
    
                schoolsSnapshot.forEach((schoolDoc) => {
                    let school = schoolDoc.data();
                    school.id = schoolDoc.id;
    
                    funds_raised += school.funds_raised;
                    contributions += school.contributions;
                    donors += school.donors;
                })
    
                this.setState({
                    loading: false,
                    target: school_id,
                    data: {
                        funds_raised: funds_raised, 
                        contributions: contributions,
                        donors: donors
                    }
                });

                if (this.state.scene === "donors") {
                    this.getDonors(school_id);
                }
            })
        } else {
            this.props.DB.collection('schools').doc(school_id).get().then((schoolDoc) => {
                let school = schoolDoc.data();
                let funds_raised = 0;
                let contributions = 0;
                let donors = 0;

                funds_raised += school.funds_raised;
                contributions += school.contributions;
                donors += school.donors;
    
                this.setState({
                    loading: false,
                    target: school_id,
                    data: {
                        funds_raised: funds_raised, 
                        contributions: contributions,
                        donors: donors
                    }
                });

                if (this.state.scene === "donors") {
                    this.getDonors(school_id);
                }
            })
        }
    }

    getDonors(school_id) {
        this.setState({loading: true});
        if (school_id === "all") {
            this.props.DB.collection('donors').get().then((donorsSnapshot) => {
                let donors = [];
                let formattedDonors = [];
                
                donorsSnapshot.forEach((donorDoc) => {
                    let donor = donorDoc.data();
                    donor.id = donorDoc.id;

                    donors.push(donor);
                })

                donors.forEach((donor, donorIndex) => {
                    formattedDonors.push(
                        <TableRow key={donor.id} style={donorIndex % 2 === 0 ? {backgroundColor: '#ccc'} : {backgroundColor: '#fff'}}>
                            <TableCell style={{textAlign: 'left'}}>{donor.name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.email}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.school_name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.class_year}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(donor.contributions).format('0,0')}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(donor.funds_raised/100).format('$0,0.00')}</TableCell>
                            <TableCell style={{fontStyle: 'italic', textAlign: 'left'}}>{donor.status}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.active ? <CheckIcon style={{color: 'green'}}/> : <CloseIcon style={{color: 'red'}}/>}</TableCell>
                            <TableCell style={{textAlign: 'left'}}><IconButton onClick={() => this.editDonor(donor)}><EditIcon style={{color: '#000'}}/></IconButton></TableCell>
                        </TableRow>
                    )
                })                
    
                this.setState({
                    loading: false,
                    donors: donors,
                    formattedDonors: formattedDonors,
                    school_id: school_id
                });
            })
        } else {
            this.props.DB.collection('donors').where("school", "==", school_id).get().then((donorsSnapshot) => {
                let donors = [];
                let formattedDonors = [];
    
                donorsSnapshot.forEach((donorDoc) => {
                    let donor = donorDoc.data();
                    donor.id = donorDoc.id;

                    donors.push(donor);
                })


                donors.forEach((donor, donorIndex) => {
                    formattedDonors.push(
                        <TableRow key={donor.id} style={donorIndex % 2 === 0 ? {backgroundColor: '#ccc'} : {backgroundColor: '#fff'}}>
                            <TableCell style={{textAlign: 'left'}}>{donor.name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.email}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.school_name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.class_year}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(donor.contributions).format('0,0')}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(donor.funds_raised/100).format('$0,0.00')}</TableCell>
                            <TableCell style={{fontStyle: 'italic', textAlign: 'left'}}>{donor.status}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.active ? <CheckIcon style={{color: 'green'}}/> : <CloseIcon style={{color: 'red'}}/>}</TableCell>
                            <TableCell style={{textAlign: 'left'}}><IconButton onClick={() => this.editDonor(donor)}><EditIcon style={{color: '#000'}}/></IconButton></TableCell>
                        </TableRow>
                    )
                })   
    
                this.setState({
                    loading: false,
                    donors: donors,
                    formattedDonors: formattedDonors,
                    school_id: school_id
                });
            })
        }
    }

    filterDonors(keyword) {
        let donors = this.state.donors;
        let formattedDonors = [];

        donors = _.filter(donors, (donor) => {

            let match = (donor.name.toLowerCase().indexOf(keyword) !== -1 || donor.email.toLowerCase().indexOf(keyword) !== -1 || donor.id.toLowerCase().indexOf(keyword) !== -1);

            return match; 
        });

        donors.forEach((donor, donorIndex) => {
            formattedDonors.push(
                <TableRow key={donor.id} style={donorIndex % 2 === 0 ? {backgroundColor: '#ccc'} : {backgroundColor: '#fff'}}>
                    <TableCell style={{textAlign: 'left'}}>{donor.name}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{donor.email}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{donor.school_name}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{donor.class_year}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{Numeral(donor.contributions).format('0,0')}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{Numeral(donor.funds_raised/100).format('$0,0.00')}</TableCell>
                    <TableCell style={{fontStyle: 'italic', textAlign: 'left'}}>{donor.status}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{donor.active ? <CheckIcon style={{color: 'green'}}/> : <CloseIcon style={{color: 'red'}}/>}</TableCell>
                    <TableCell style={{textAlign: 'left'}}><IconButton onPress={() => this.editDonor(donor)}><EditIcon style={{color: '#000'}}/></IconButton></TableCell>
                </TableRow>
            )
        })

        this.setState({formattedDonors: formattedDonors, donorSearchVisible: false});
    }

    formatSchoolMenuItems(schools) {
        let formattedSchoolMenuItems = [];
        schools.forEach((school) => {
            formattedSchoolMenuItems.push(<MenuItem value={school.id}>{school.name}</MenuItem>);
        })
    }

    editSchool(school) {
        this.setState({current_school: school, schoolEditingVisible: true});
    }

    updateSchool() {
        this.setState({loading: true});

        let school = this.state.current_school;
        let school_id = school.id;

        this.props.DB.collection("schools").doc(school_id).update({
            active: school.active,
            service_fee_percentage: school.service_fee_percentage
        }).then(() => {
            this.setState({schoolEditingVisible: false, loading: false});
            this.getSchools();
        }).catch((error) => {
            this.setState({message: error.message, loading: false})
        })
    }

    toggleAddSchoolFormVisibility() {
        this.setState({addSchoolFormVisible: !this.state.addSchoolFormVisible});
    }

    toggleSchoolEditingVisibility() {
        this.setState({schoolEditingVisible: !this.state.schoolEditingVisible});
    }

    toggleDonorSearchVisibility() {
        this.setState({donorSearchVisible: !this.state.donorSearchVisible});
    }

    toggleDonorEditingVisibility() {
        this.setState({donorEditingVisible: !this.state.donorEditingVisible});
    }

    editDonor(donor) {
        this.setState({current_donor: donor, donorEditingVisible: true});
    }

    updateDonor() {
        this.setState({loading: true});

        let donor = this.state.current_donor;
        let donor_id = donor.id;

        this.props.DB.collection("donors").doc(donor_id).update({
            active: donor.active
        }).then(() => {
            this.setState({donorEditingVisible: false, loading: false});
            this.getDonors(this.state.school_id);
        }).catch((error) => {
            this.setState({message: error.message, loading: false})
        })
    }

    createDonorCSV() {
        let donors = this.state.donors;
        let formattedCSV = [["Name", "Email", "School", "Class", "Contributions", "Funds Raised", "Status", "Active"]];
        donors.forEach((donor) => {
            formattedCSV.push([donor.name, donor.email, donor.school_name, donor.class_year, donor.contributions, donor.funds_raised, donor.status, donor.active]);
        })

        this.setState({csvData: formattedCSV, csvLinkVisible: true});
    }

    render() {
        const { classes } = this.props;

        return(
            <React.Fragment>
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={this.state.message !== null}
                    onClose={() => this.setState({message: null})}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                >
                    <SnackbarContent
                        style={this.state.snackbarStyle}
                        message={this.state.message}
                    />    
                </Snackbar>
                <AppBar style={{backgroundColor: '#243b52'}}>
                    <Toolbar>
                        <Container style={{display: 'flex'}}>
                            <img style={{height: '2rem', margin: '0.5rem 1rem 0.5rem 0'}} src={logo} alt="logo"/>
                            <Typography variant="h1" style={{flexGrow: 1, fontSize: '1rem', fontWeight: '700', lineHeight: '3', color: '#fff'}}>
                                Admin
                            </Typography>
                            <Button onClick={() => this.setState({scene: "funds_raised"})} className={classes.headerNavItem}>Funds Raised</Button>
                            <Button onClick={() => {this.getDonors(this.state.target); this.setState({scene: "donors"})}} className={classes.headerNavItem}>Donors</Button>
                            <Button onClick={() => {this.getDonors(this.state.target); this.setState({scene: "schools"})}} className={classes.headerNavItem}>Schools</Button>
                            <Button onClick={() => this.props.unauthorize()} className={classes.headerNavItem}>Log Out</Button>
                        </Container>
                    </Toolbar>
                </AppBar>
                <Container id="dashboard-scene">
                    {/* FUNDS RAISED SCENE */}
                    {this.state.scene === "funds_raised" && <Grid container spacing={6} style={{paddingTop: '6rem'}}>
                        <Grid xs={12} style={{display: 'flex', paddingBottom: 0, marginBottom: 0}} item>
                            <Typography className={classes.pageTitle} style={{flexGrow: 1, marginRight: '1.5rem'}}>Funds Raised</Typography>
                            <FormControl variant="outlined" className={classes.formControl} style={{minWidth: '10rem'}}>
                                <Select
                                    value={this.state.target}
                                    onChange={(event) => this.updateTarget(event.target.value)}
                                    style={{backgroundColor: '#fff'}}
                                >
                                    {this.state.formattedSchoolMenuItems}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item md={4}>
                                <Paper className={classes.statCard} elevation={1}>
                                    <Typography variant="h1" className={classes.stat}>{this.state.loading ? <CircularProgress style={{color: '#58c26e'}}/> : Numeral(this.state.data.funds_raised/100).format('$0,0.00')}</Typography>
                                    <Typography varient="subtitle2" className={classes.statDescription}>Total Funds Raised</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={8} style={{width: '100%'}}>
                                <Paper style={{width: '100%', padding: '1rem', backgroundColor: '#fff'}} elevation={1}>
                                    <ResponsiveContainer width={'99%'} height={300}>
                                        <BarChart data={this.state.chart_data.funds_raised} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip formatter={(value) => {return Numeral(value).format('$0,0.00')}} />
                                            <Legend />
                                            <Bar dataKey="Funds Raised" fill="#5bc977" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <Grid item xs={12} md={4}>
                                <Paper className={classes.statCard} elevation={1}>
                                    <Typography variant="h1" className={classes.stat}>{this.state.loading ? <CircularProgress style={{color: '#58c26e'}}/> : Numeral(this.state.data.contributions).format('0,0')}</Typography>
                                    <Typography varient="subtitle2" className={classes.statDescription}>Contributions</Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={8} style={{width: '100%'}}>
                                <Paper style={{width: '100%', padding: '1rem', backgroundColor: '#fff'}} elevation={1}>
                                    <ResponsiveContainer width={'99%'} height={300}>
                                        <BarChart data={this.state.chart_data.round_up_contributions} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip formatter={(value) => {return Numeral(value).format('0,0')}} />
                                            <Legend />
                                            <Bar dataKey="Round Ups" fill="#243b52" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {/* DONORS SCENE */}
                    {this.state.scene === "donors" && <Grid container spacing={6} style={{paddingTop: '6rem'}}>
                        <Grid xs={12} style={{display: 'flex', paddingBottom: 0, marginBottom: 0}} item>
                            <Typography className={classes.pageTitle} style={{flexGrow: 1, marginRight: '1.5rem'}}>Donors <small style={{color: '#58c26e', fontWeight: '200'}}>{this.state.donors.length}</small></Typography>
                            <IconButton
                                onClick={() => this.toggleDonorSearchVisibility()}
                                style={{color: '#58c26e', marginRight: '1rem'}}
                            >
                                <SearchIcon/>
                            </IconButton>
                            <IconButton
                                onClick={() => this.createDonorCSV()}
                                style={{color: '#243b52', marginRight: '1rem'}}
                            >
                                <DownloadIcon/>
                            </IconButton>
                            <FormControl variant="outlined" className={classes.formControl} style={{minWidth: '10rem'}}>
                                <Select
                                    value={this.state.target}
                                    onChange={(event) => this.updateTarget(event.target.value)}
                                    style={{backgroundColor: '#fff'}}
                                >
                                    {this.state.formattedSchoolMenuItems}
                                </Select>
                            </FormControl>
                        </Grid>
                        {this.state.keyword && <Grid item xs={12}>
                            Filters: <Chip label={this.state.keyword} onDelete={() => {
                                this.filterDonors("");
                                this.setState({keyword: ""});
                            }}/>
                        </Grid>}
                        <Grid item xs={12}>
                            <Table style={{backgroundColor: '#fff', borderWidth: '1px'}}>
                                <TableHead style={{backgroundColor: '#243b52'}}>
                                    <TableRow>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Name</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>School</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Class</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Contributions</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Funds Raised</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Status</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Active</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Edit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.formattedDonors}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>}

                    {/* SCHOOLS SCENE */}
                    {this.state.scene === "schools" && <Grid container spacing={6} style={{paddingTop: '6rem'}}>
                        <Grid xs={12} style={{display: 'flex', paddingBottom: 0, marginBottom: 0}} item>
                            <Typography className={classes.pageTitle} style={{flexGrow: 1, marginRight: '1.5rem'}}>Schools <small style={{color: '#58c26e', fontWeight: '200'}}>{this.state.schools.length}</small></Typography>
                            {/*<IconButton onClick={() => this.toggleAddSchoolFormVisibility()}>
                                <AddCircleIcon style={{color: 'forestgreen'}}/>
                            </IconButton>*/}
                        </Grid>
                        <Grid item xs={12}>
                            <Table style={{backgroundColor: '#fff', borderWidth: '1px'}}>
                                <TableHead style={{backgroundColor: '#243b52'}}>
                                    <TableRow>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>School</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Donors</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Contributions</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Funds Raised</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Service Fee</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Active</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Edit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.formattedSchools}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>}
                    {/* ADD SCHOOL DIALOG */}
                    <Dialog open={this.state.addSchoolFormVisible} onClose={() => this.toggleAddSchoolFormVisibility()} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{padding: '12px 24px 0px'}}>Add School</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="School Name"
                                autoFocus
                                margin="dense"
                                id="name"
                                variant="outlined"
                                placeholder="school name"
                                value={this.state.school_name}
                                disabled={this.state.loading}
                                onChange={(event) => this.setState({school_name: event.target.value})}
                                fullWidth
                            />
                            <TextField
                                label="School Email"
                                margin="dense"
                                id="email"
                                variant="outlined"
                                placeholder="example@email.com"
                                value={this.state.school_email}
                                disabled={this.state.loading}
                                onChange={(event) => this.setState({school_email: event.target.value})}
                                fullWidth
                            />
                            <TextField
                                label="Primary Color"
                                margin="dense"
                                id="primary_color"
                                variant="outlined"
                                placeholder="hex value #000000"
                                value={this.state.primary_color}
                                disabled={this.state.loading}
                                onChange={(event) => this.setState({primary_color: event.target.value})}
                                fullWidth
                            />
                            <TextField
                                label="Secondary Color"
                                margin="dense"
                                id="secondary_color"
                                variant="outlined"
                                placeholder="hex value #000000"
                                disabled={this.state.loading}
                                value={this.state.secondary_color}
                                onChange={(event) => this.setState({secondary_color: event.target.value})}
                                fullWidth
                            />
                            <TextField
                                InputProps={{accept: '.png, .jpg, .gif, .jpeg'}}
                                helperText=".png or .jpg files at least 400x400 work best"
                                disabled={this.state.loading}
                                label="Logo"
                                type="file"
                                value={this.state.logo[0]}
                                variant="outlined"
                                onChange={(event) => this.addImage(event)}
                            />
                            <TextField
                                InputProps={{accept: '.png, .jpg, .gif, .jpeg'}}
                                helperText=".png or .jpg files at least 400x400 work best"
                                disabled={this.state.loading}
                                label="Campus Image"
                                type="file"
                                value={this.state.hero[0]}
                                variant="outlined"
                                onChange={(event) => this.addImage(event)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button style={{textTransform: 'none'}} onClick={() => this.toggleAddSchoolFormVisibility()}>
                                Cancel
                            </Button>
                            <Button style={{textTransform: 'none', backgroundColor: '#58c16c', color: '#fff'}} onClick={() => this.addSchool()}>
                                Add School
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* DONOR SEARCH DIALOG */}
                    <Dialog open={this.state.donorSearchVisible} onClose={() => this.toggleDonorSearchVisibility()} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{padding: '12px 24px 0px'}}>Search</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Find donors by email, name, or id.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                variant="outlined"
                                placeholder="email, name, or id"
                                value={this.state.keyword}
                                onChange={(event) => this.setState({keyword: event.target.value})}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button style={{textTransform: 'none'}} onClick={() => {this.filterDonors(""); this.setState({keyword: ""}); this.toggleDonorSearchVisibility()}}>
                                Cancel
                            </Button>
                            <Button style={{textTransform: 'none', backgroundColor: '#58c16c', color: '#fff'}} onClick={() => this.filterDonors(this.state.keyword)}>
                                Search
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* EDIT SCHOOL MODAL */}
                    <Dialog open={this.state.schoolEditingVisible} onClose={() => this.toggleSchoolEditingVisibility()} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{padding: '12px 24px 0px'}}>Manage School</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.current_school.name}<br/>
                                {this.state.current_school.email}
                            </DialogContentText>
                            {this.state.loading && <LinearProgress/>}
                            <TextField
                                type="number"
                                value={this.state.current_school.service_fee_percentage}
                                onChange={(event) => this.setState({current_school: {...this.state.current_school, service_fee_percentage: parseInt(event.target.value)}})}
                                style={{backgroundColor: '#fff', marginBottom: 20}}
                                label="Service Fee Percentage" 
                                fullWidth
                                variant="outlined"
                            />
                            <Select
                                value={this.state.current_school.active}
                                onChange={(event) => this.setState({current_school: {...this.state.current_school, active: event.target.value}})}
                                style={{backgroundColor: '#fff'}}
                                label="Active"
                                fullWidth
                                variant="outlined"
                            >
                                <MenuItem key={true} dense value={true}>Active</MenuItem>
                                <MenuItem key={false} dense value={false}>Inactive</MenuItem>
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={this.state.loading} style={{textTransform: 'none'}} onClick={() => this.toggleSchoolEditingVisibility()}>
                                Cancel
                            </Button>
                            <Button disabled={this.state.loading} style={{textTransform: 'none', backgroundColor: '#58c16c', color: '#fff'}} onClick={() => this.updateSchool()}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* EDIT DONOR MODAL */}
                    <Dialog open={this.state.donorEditingVisible} onClose={() => this.toggleDonorEditingVisibility()} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{padding: '12px 24px 0px'}}>Manage Donor</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.current_donor.name}<br/>
                                {this.state.current_donor.email}
                            </DialogContentText>
                            {this.state.loading && <LinearProgress/>}
                            <Select
                                value={this.state.current_donor.active}
                                onChange={(event) => this.setState({current_donor: {...this.state.current_donor, active: event.target.value}})}
                                style={{backgroundColor: '#fff'}}
                                label="Active"
                                fullWidth
                                variant="outlined"
                            >
                                <MenuItem key={true} dense value={true}>True</MenuItem>
                                <MenuItem key={false} dense value={false}>False</MenuItem>
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={this.state.loading} style={{textTransform: 'none'}} onClick={() => this.toggleDonorEditingVisibility()}>
                                Cancel
                            </Button>
                            <Button disabled={this.state.loading} style={{textTransform: 'none', backgroundColor: '#58c16c', color: '#fff'}} onClick={() => this.updateDonor()}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {this.state.csvLinkVisible && <CSVDownload data={this.state.csvData} target="_blank" />}
                </Container>
            </React.Fragment>
        )
    }
}

export default withStyles(CSS)(ReactTimeout(Dashboard));
