const CSS = theme => ({
    container: {
        padding: 24, 
        width: '100vw',
        margin: 0,
        justifyContent: 'center'
    },
    flush_container: {
        padding: 0,
        width: '100vw',
        height: '100vh' - 60,
        margin: 0,
        justifyContent: 'center'
    },
    statCard: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: '4rem 0rem',
        width: '100%'
    },
    stat: {
        fontSize: '2.5rem',
        fontWeight: '700',
        color: '#444',
        lineHeight: 1,
        marginBottom: '0.5rem'
    },
    statDescription: {
        color: '#222',
        fontWeight: '200'
    },
    static_container: {
        marginTop: -50,
        padding: 24, 
        width: '100vw',
        justifyContent: 'center'
    },
    successMessage: {
        backgroundColor: 'green',
    },
    errorMessage: {
        backgroundColor: 'red'
    },
    accountHeader: {
        backgroundColor: '#222'
    },
    tableContainer: {
        overflowX: 'auto'
    },
    table: {
        backgroundColor: '#fff',
        minWidth: '900px'
    },
    title: {
        fontWeight: '700',
        marginBottom: '2.5px'
    },
    dashboardIcon: {
        fontSize: 50
    },
    blockInput: {
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        textAlign: 'left'
    },
    blockInputWithWhiteBackground: {
        backgroundColor: '#ffffff',
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        textAlign: 'left'
    },
    tableHeader: {
        fontWeight: '700'
    },
    center: {
        textAlign: 'center',
        width: '100%'
    },
    mobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },  
    },
    desktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    headerNav: {     
        [theme.breakpoints.up('md')]: {
            maxWidth: '1280px',
            width: '100%',
            margin: '0 auto'
        }
    },
    headerNavItem: {     
        [theme.breakpoints.up('xs')]: {
            color: '#fff',
            textTransform: 'none'
        }
    },
    navigation: {
        [theme.breakpoints.down('md')]: {
            backgroundColor: '#000'
        },        
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#f5f5f5'
        }
    },
    mobileNavItem: {
        fontSize: '1.5rem',
        fontWeight: '400',
        marginBottom: '2rem',
    },
    hero: {
        [theme.breakpoints.up('xs')]: {
            margin: '1rem',
            paddingTop: '2rem',
            textAlign: 'left',
            backgroundColor: '#f5f5f5'
        },
        [theme.breakpoints.up('md')]: {
            margin: 0,
        } 
    },
    previewHero: {
        textAlign: 'center',
        backgroundColor: '#000'
    },
    heroLeft: {
        alignSelf: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            padding: '1rem'
        },
    },
    heroRightTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            lineHeight: '1rem',
            marginBottom: '5px'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem',
            lineHeight: '1rem',
            marginBottom: '10px'
        }
    },
    heroTitle: {
        lineHeight: 0.9,
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '7vh'
        }
    },
    heroSubtitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            textAlign: 'center'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem',
        }
    },
    heroRight: {
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            textAlign: 'center',
            paddingTop: '1rem'
        },
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            textAlign: 'center'
        }
    },
    pageTitle: {
        [theme.breakpoints.up('xs')]: {
            color: '#444',
            fontSize: '1rem',
            fontWeight: '600',
            marginBottom: '0.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },        
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        }
    },
    pageDescription: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '0.8rem',
            fontWeight: '500',
            marginBottom: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }, 
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        }
    },
    heroChevron: {
        position: 'absolute',
        height: '4rem',
        top: '50%',
        cursor: 'pointer'   
    },
    heroChevronLeft: {
        left: '0',
    },
    heroChevronRight: {
        right: '0',
    },
    previewLogo: {
        [theme.breakpoints.down('sm')]: {
            width: '50%',
            paddingTop: '5rem'
        },
        [theme.breakpoints.up('md')]: {
            width: '12rem',
            paddingTop: '10rem'
        },      
    },    
    logo: {
        [theme.breakpoints.down('sm')]: {
            width: '6rem',
        },
        [theme.breakpoints.up('md')]: {
            width: '10rem',
            marginRight: '1rem'
        },      
    },
    listItem: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0.8rem',
            fontSize: '0.8rem'
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '1rem',
            fontSize: '1.2rem',
            lineHeight: 1.25
        },       
    },
    subListItem: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0.5rem',
            fontSize: '0.8rem'
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '1rem',
            fontSize: '1rem'
        },       
    },
    appStoreBadge: {
        [theme.breakpoints.down('sm')]: {
            height: '3rem',
        },
        [theme.breakpoints.up('md')]: {
            height: '60px',
            marginRight: '1rem'
        },      
    },
    heroPhone: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '60vh',
        },      
    },
    howItWorksPhone: {
        [theme.breakpoints.up('xs')]: {
            width: '80%',
        },
        [theme.breakpoints.up('md')]: {
            width: '40vh',
        },    
    },
    howItWorksArrows: {
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }, 
    },
    howItWorksArrowsMobile: {
        [theme.breakpoints.up('xs')]: {
            position: 'fixed',
            zIndex: 99999,
            top: '60%',
            cursor: 'pointer',
            width: '2rem'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    sources: {
        color: 'blue',
        cursor: 'pointer'
    },
    floatingLabelFocusStyle: {
        color: '#000'
    },
    lessonPlan: {
        [theme.breakpoints.up('md')]: {
            minHeight: '500px'
        }
    },
    media: {
        height: 200
    }

})

export default CSS;