import React from 'react';
import LogIn from './components/LogIn';
import Dashboard from './components/Dashboard';
import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/storage';

// import DATA from './demodonors';

const CONFIG = {
  apiKey: "AIzaSyBUZPZDE45UHKZhwjQiJ2_E1erBegMylhI",
  authDomain: "hbcu-change.firebaseapp.com",
  databaseURL: "https://hbcu-change.firebaseio.com",
  projectId: "hbcu-change",
  storageBucket: "hbcu-change.appspot.com",
  messagingSenderId: "434200357772",
  appId: "1:434200357772:web:de94971f461ba23e86c4a9",
  measurementId: "G-64JRHW5BC3"
};

firebase.initializeApp(CONFIG);
const AUTH = firebase.auth();
const STORAGE = firebase.storage().ref();
const DB = firebase.firestore();


class App extends React.Component {

  constructor() {
    super();

    this.state = {
      scene: "auth",
      authorized: false,
      message: null
    }

    this.authorize = this.authorize.bind(this);
    this.unauthorize = this.unauthorize.bind(this);

    // DATA.forEach((demo_donor) => {
    //   DB.collection('donors').doc(demo_donor.id).set(demo_donor);
    //   console.log(demo_donor);
    // })
  }

  authorize() {
    this.setState({
      authorized: true,
      scene: 'dashboard'
    });
  }

  unauthorize() {
    AUTH.signOut().then(() => {
      this.setState({authorized: false, scene: 'auth'})
    }).catch((error) => {
      this.setState({message: error.message});
    })
  }

  render() {
    return (
      <div style={{backgroundColor: '#f5f5f5', minHeight: '100vh'}}>
        {this.state.scene === "auth" && <LogIn AUTH={AUTH} DB={DB} authorize={this.authorize}  unauthorize={this.unauthorize} message={this.state.message}/>}
        {(this.state.scene === "dashboard" && AUTH.currentUser && this.state.authorized) && <Dashboard DB={DB} STORAGE={STORAGE} unauthorize={this.unauthorize}/>}
      </div>
    );
  }
}

export default App;
